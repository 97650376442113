.candleAddPage {
    .label-error-text-display {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__mustIncluded {
        color: red;
    }

    .cost-section {
        margin-bottom: 5%;

        &__price {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }


    .colorDisplay {
        margin-bottom: 7%;

        input[type="color"] {
            width: 37%;
            padding: 0 0.5%;

            @media only screen and (max-width: 768px) {
                width: 75%;
            }
        }

        &__colorsList {
            display: flex;
            flex-wrap: wrap;
            min-height: 88px;

            &--color-item {
                border: 1px solid grey;
                border-radius: 100px;
                padding: 0.5%;
                justify-content: center;
                align-items: center;
                display: flex;
                margin: 1%;

                div {
                    margin-top: 3%;
                    color: white;
                    text-shadow: 0 0 3px black;
                    width: 60px;
                    height: 60px;
                    border-radius: 100px;
                    border: 2px solid grey;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 1%;
                }
            }
        }
    }

    .fragDisplay {
        margin-bottom: 7%;

        &__fragranceList {
            display: flex;
            flex-wrap: wrap;
            min-height: 88px;

            &--frag-item {
                border: 1px solid grey;
                border-radius: 100px;
                padding: 0.5%;
                justify-content: center;
                align-items: center;
                display: flex;
                margin: 1%;

                div {
                    min-width: 100px;
                    height: 60px;
                    border-radius: 100px;
                    border: 2px solid grey;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 1%;
                }
            }
        }
    }

    .shapeDisplay {
        margin-bottom: 5%;
    }

    .descDisplay {
        margin: 5% 0%;

        &__list {
            list-style-type: circle;
            list-style-position: inside;
            margin-bottom: 3%;
        }

        textarea {
            width: 100%;
            min-height: 100px;
        }
    }

    .selectDisplay {
        width: 36%;

        @media only screen and (max-width: 768px) {
            width: 70%;
        }
    }

    .candleImages {
        min-width: 833px;

        @media only screen and (max-width: 768px) {
            min-width: 500px;
        }

        @media only screen and (max-width: 420px) {
            min-width: 280px;
        }
    }

    .choosenImages {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        min-height: 400px;

        .singleImage {
            position: relative;

            &__first {
                border: 1px solid grey;
                text-align: center;
            }

            &__change-btn {
                position: absolute;
                top: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.2);
                width: 100%;
                height: 100%;
                margin: 0 auto;
                display: none;
                justify-content: center;
                align-items: center;

                button {
                    background-color: white;
                    padding: 1% 5%;
                }
            }

            &:hover .singleImage__change-btn {
                display: flex;
            }

            img {
                width: 80%;
                height: 90%;
                margin: 0 auto;
            }
        }

        @media only screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (max-width: 420px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .addCandleBtn {
        display: flex;
        justify-content: center;

        &__btn {
            color: white;
            padding: 1% 2%;
            border-radius: 10px;
            margin: 3% 0%;

            @media only screen and (max-width: 420px) {
                margin: 10% 0%;
                padding: 3% 4%;
            }

            &--loader {
                background-color: grey;
            }

            &--waiting {
                background-color: green;
            }
        }
    }
}