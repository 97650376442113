.aboutDash {
    text-align: center;

    &__editTitle,
    &__editDesc,
    &__editTitle--editWindow {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    &__editTitle--editWindow {
        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }


        textarea {
            width: 35ch;
            height: 300px;
        }
        
        &--btns {
            display: flex;

            @media only screen and (max-width: 768px) {
                margin: 5%;
            }
        }
    }

    &__text {
        width: 90%;
        margin: 0 auto;
        min-width: 280px;
        line-height: 2.25;
    }

    &__images {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1%;
        margin: 5% 0% 10%;

        @media only screen and (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 0;
        }

        &--imageItem {
            width: 100%;
            position: relative;

            @media only screen and (max-width: 768px) {
                margin-bottom: 5%;
            }

            &:hover .aboutDash__images--imageItem__btnSection {
                display: flex;
            }

            img {
                width: 100%;
                min-width: 280px;
            }

            &__btnSection {
                position: absolute;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.2);
                width: 100%;
                height: 100%;
                top: 0;
                display: none;

                @media only screen and (max-width: 768px) {
                    display: flex;
                }
            }

            button {
                width: 50%;
                font-size: 24px;
                padding: 2% 0%;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                background-color: aliceblue;
                border-radius: 15px;
            }
        }
    }
}

.popupEditImage {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 0.5fr;

    p {
        grid-area: 1 / 1 / 2 / 3;
    }

    &--uploadBtn--grided {
        grid-area: 2 / 1 / 3 / 3;
    }

    &--uploadBtn {
        grid-area: 2 / 2 / 3 / 3;
    }

    img {
        height: 80%;
    }

    &--text {
        background-color: rgb(129, 14, 14);
        color: white;
        margin-bottom: 2%;
    }

    &--btns {
        grid-area: 3 / 1 / 4 / 3;
        display: flex;

        button {
            margin: 4% 0%;
        }
    }
}