.candle-images {
    &__top-show-big {
        width: 100%;
        min-width: 200px;
        height: 77vh;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__bottom-show {
        display: flex;

        .imageEl {
            width: 100%;
            height: 250px;
            position: relative;


            &.choosenImage {
                border: 2px solid black;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &:hover .candle-images__bottom-show--deleteEl {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &--addEl {
            background-color: rgb(241, 219, 250);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &--deleteEl {
            position: absolute;
            top: 0;
            background-color: rgba(0, 0, 0, 0.4);
            width: 100%;
            height: 100%;
            display: none;

            @media only screen and (max-width: 736px) {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            button {
                background-color: white;
                border-radius: 50px;
                padding: 3%;
            }
        }
    }
}