.mainImg {
    .bigImg {
        width: 100%;
        height: 80vh;
        object-fit: cover;
    }
}

.gridImg {
    gap: 1%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 3% 0%;

    @media only screen and (max-width:1000px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 450px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 0%;
    }
}

.link-to-candle {
    text-align: center;
    position: relative;

    img {
        width: 100%;
        height: 65vh;
        object-fit: cover;
    }
}

.price-details {
    font-size: clamp(1.2rem, 0.897vw + 0.923rem, 2rem);
    font-weight: 400;

    &__costs {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.regularPrice,
.salePrice {
    color: black;
}

.salePrice {
    margin-right: 3%;
}

.priceBefore {
    text-decoration: line-through;
    color: rgb(123, 123, 123);
}

.candle-show {
    position: relative;

    &:hover button {
        display: flex;
    }

    button {
        position: absolute;
        top: 0;
        z-index: 1000;
        background-color: white;
        border-radius: 50px;
        padding: 3%;
        margin: 5%;
        display: none;

        @media only screen and (max-width: 768px) {
            display: flex;
        }
    }
}