.navigation-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    margin: 0 auto;
    height: 70px;
    position: relative;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }

    &__all-links {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 768px) {
            padding: 5% 0%;
            position: absolute;
            top: 100%;
            height: 50vh;
            flex-direction: column;
            z-index: 10000;
            background-color: white;
            box-shadow: 0px 10px 15px 1px rgb(182, 182, 182);
        }
    }

    button {
        margin-right: 5%;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #EBDBFF;
        border-radius: 50px;
        margin: 2% 0%;
        width: 165px;
        height: 47px;
    }

    .active {
        color: #EBDBFF;
        background-color: #8524FF;
    }
}