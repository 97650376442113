.popup {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.573);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    &__window {
        background-color: #ffffff;
        width: 41%;
        min-height: 43%;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 280px;

        @media only screen and (max-width: 768px) {
            width: 90%;
        }

        &--confirmBtns {
            display: flex;
        }
    }
}