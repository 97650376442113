.dashboard {
    background-color: #EBDBFF;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    direction: rtl;

    &__window {
        width: 90%;
        min-width: 320px;
        background-color: white;
        min-height: 80vh;
        border-radius: 25px;
        box-shadow: 0px 0px 67px 2px rgba(178, 167, 192, 1);

        @media only screen and (max-width: 768px) {
            height: fit-content;
        }
    }

    main {
        width: 100%;
        margin: 1% 0;
        display: flex;
        align-items: center;
        justify-content: center;

        h1,
        h2,
        h3 {
            margin: 1% 0%;

            @media only screen and (max-width: 768px) {
                margin: 5% 0%;
            }
        }

        h1 {
            font-size: clamp(2rem, 1vw + 1.8rem, 3rem);
            text-align: center;
            font-weight: 700;
        }

        h2 {
            font-size: clamp(1.75rem, 0.75vw + 1.6rem, 2.5rem);
            font-weight: 500;
        }

        h3 {
            font-size: clamp(1.375rem, 0.625vw + 1.25rem, 2rem)
        }

        p,
        label,
        input,
        textarea,
        select, 
        .addCandleBtn {
            font-size: clamp(1.25rem, 0.25vw + 1.2rem, 1.5rem);
            margin: 1% 0%;
        }

        label {
            font-weight: 500;
        }

        input,
        textarea,
        select {
            direction: rtl;
            background-color: #EBDBFF;
            border-radius: 10px;
        }

        input,
        textarea {
            padding: 1% 2%;
        }

        select {
            padding: 1%;
        }

        .error-text {
            font-size: 18px;
            color: red;
            margin-right: 10px;
        }
    }

    .form-btn_active,
    .form-btn_disable {
        width: 53%;
        border-radius: 20px;
        padding: 15px 0px;
        min-width: 210px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
    }

    .form-btn_disable {
        background-color: rgb(127, 127, 127);
        color: #FFFFFF;
    }

    .form-btn_active {
        background-color: #8524FF;
        color: #EBDBFF;
    }

    .loginRegBtn {
        margin: 5% auto 0%;
    }

    .agree-btn,
    .cancel-btn,
    .action-loading {
        color: #ffffff;
        width: 150px;
        font-size: clamp(1.125rem, 0.25vw + 1.075rem, 1.375rem);
        border-radius: 10px;
        margin-right: 2%;

        @media only screen and (max-width: 768px) {
            padding: 3% 1%;
        }
    }

    .cancel-btn {
        background-color: red;
    }

    .agree-btn {
        background-color: green;
    }

    .action-loading {
        background-color: grey;
    }
}



.dashboardMargin {
    padding: 10vh 0;
}

@import "./auth/indexAuth";
@import "./navbar/nav-bar";
@import "./users/users";
@import "./about/about";
@import "./category/categoryPage";
@import "./payment/payment";
@import "./dashboard/mainDashboard";
@import "./UI/PopUp/popUp";
@import "./oneCandle/candle";
@import "./newCandleAdd";