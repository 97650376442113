.categoryGrid {
    margin-top: 1%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 10px;
    justify-content: center;
}

.cubeGrid,
.nameCategory {
    font-size: clamp(1.5rem, 1.538vw + 1.154rem, 3rem);
    font-weight: 900;
}

.cubeGrid {
    background-color: #ebdbff;
    width: 20%;
    min-width: 150px;
    height: 150px;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        text-align: center;
    }
}

@import "./categoryCandles";