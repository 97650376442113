.user {
    min-width: 300px;
    margin: 0 auto;

    .userName {
        height: 350px;
        overflow-x: auto;
        overflow-x: hidden;
    }

    .gridUserName {
        margin: 2% 0%;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        border: solid 1px;
        border-width: 1px 0;
        border-color: #8524FF;
    }

    .gridName {
        display: flex;
        align-items: baseline;
    }

    .iconName {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 62px;
        height: 62px;
        font-size: 20px;
        border-radius: 100%;
        background-image: linear-gradient(#ebdbff, #ffffff);
        margin-left: 10%;
    }

    .delete {
        color: rgb(212, 25, 25);
        background-color: transparent;
    }

    .addIcon {
        width: 36px;
        height: 36px;
    }

    .link {
        display: flex;
        color: #EBDBFF;
        background-color: #8524FF;
        width: 413px;
        height: 73px;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        gap: 10%;
    }

    .adminBtn {
        margin: 0 auto;
    }
}